import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import tw from "tailwind.macro"

const AboutDiv = styled.div`
  h1 {
    ${tw`mt-2 text-center`};
  }
`

const About = () => (
  <Layout>
    <AboutDiv>
      <h1>About Us</h1>
      <p>
        Our mission is to give early stage startups a fighting chance against
        their well funded and more established competitors.
      </p>
      <p>
        Though we don't limit ourselves and are open to all startups, we love to
        write about startups that have a product i.e. have launched but haven't
        raised any post-seed funds.
      </p>
    </AboutDiv>
  </Layout>
)

export default About
